import { createRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';

import { ArrowLeft, ArrowRight } from '@/icons';
import { IconButton } from '@/molecules';

import type { Options, Splide as TypeSplide } from '@splidejs/splide';
import type { FunctionComponent, ReactNode } from 'react';
import type { TypeSliderProductProps } from './types';

// TODO: Reemplazar la implementación custom de las flechas con SplideTrack
// @see https://github.com/Splidejs/splide/discussions/769#discussioncomment-2639518
// TODO: Bug con loop y la extensión grid
/**
 * SliderProduct
 */
export const SliderProduct: FunctionComponent<TypeSliderProductProps> = ({
  className = '',
  splideClassName = '',
  type = 'slide',
  perPage = 6,
  height = 'h-[var(--card-size-h)]',
  arrowPosition = 'end',
  centerSlide = false,
  outline = true,
  breakpoints = {
    1280: {
      perPage: 5,
    },
    1024: {
      perPage: 4,
    },
    768: {
      perPage: 3,
      padding: 40,
    },
    640: {
      padding: 40,
      perPage: 2,
    },
    360: {
      padding: 40,
      perPage: 1,
    },
  },
  gap = '1px',
  extensions = ['none'],
  grid = {
    rows: 1,
    cols: 2,
    gap: {
      row: '1rem',
      col: '1.5rem',
    },
  },
  children,
  alwaysShowArrows = false,
}: TypeSliderProductProps) => {
  const OUTLINE_PADDING = outline ? '1px' : '0';
  const DEFUALT_PADDING = '16px';
  const splideRef = createRef<Splide>();
  const prevSlide = () => splideRef.current?.splide?.go('<');
  const nextSlide = () => splideRef.current?.splide?.go('>');
  const [beginningOrEnd, setBeginningOrEnd] = useState({
    disabledPrev: true,
    disabledNext: false,
  });
  const checkPaginationState = (splide: TypeSplide) =>
    setBeginningOrEnd({
      disabledPrev: splide.Components.Controller.getPrev() === -1,
      disabledNext: splide.Components.Controller.getNext() === -1,
    });
  const adjustPadding = (
    splide: TypeSplide,
    track: HTMLElement,
    rootParent: HTMLElement | null,
    start: number,
    last: number,
    padding: Options['padding'],
  ) => {
    if (padding) {
      if (start === splide.index) {
        track.style.paddingLeft = OUTLINE_PADDING;
        if (rootParent) rootParent.style.paddingLeft = DEFUALT_PADDING;
      } else {
        track.style.paddingLeft = `${padding}px`;
        if (rootParent) rootParent.style.paddingLeft = '0';
      }

      if (last === splide.index) {
        track.style.paddingRight = OUTLINE_PADDING;
        if (rootParent) rootParent.style.paddingRight = DEFUALT_PADDING;
      } else {
        track.style.paddingRight = `${padding}px`;
        if (rootParent) rootParent.style.paddingRight = '0';
      }
    } else {
      track.style.paddingLeft = OUTLINE_PADDING;
      track.style.paddingRight = OUTLINE_PADDING;

      if (rootParent) rootParent.style.paddingLeft = '0';
      if (rootParent) rootParent.style.paddingRight = '0';
    }
  };
  const adjustAllPaddings = (splide: TypeSplide) => {
    const last = splide.Components.Controller.getEnd();
    const start = 0;
    const padding = splide.options.padding;
    const track = splide.Components.Elements.track;
    const root = splide.Components.Elements.root;
    const rootParent = root.parentElement;
    const breakpoints = splide.options.breakpoints;

    if (breakpoints) {
      Object.entries(breakpoints).forEach(([key, value], index) => {
        const breakpointKey = Object.keys(breakpoints)[index - 1];

        if (
          window.screen.width <= parseInt(key) &&
          breakpointKey &&
          ((index > 0 && window.screen.width > parseInt(breakpointKey)) ||
            index === 0)
        ) {
          adjustPadding(splide, track, rootParent, start, last, value.padding);
        }
      });
    }

    adjustPadding(splide, track, rootParent, start, last, padding);
  };

  /**
   * Render slides.
   *
   * @return Slide nodes.
   */
  const renderSlides = (): ReactNode[] =>
    children?.map((child, index) => (
      <SplideSlide
        key={index}
        className={` ${centerSlide ? 'flex items-center justify-center' : ''} `}
      >
        {child}
      </SplideSlide>
    ));

  const options: Options = {
    type: type,
    perPage: perPage,
    breakpoints: breakpoints,
    perMove: 1,
    pagination: false,
    arrows: false,
    height: height,
    lazyLoad: true,
    ...(extensions.includes('grid') ? { grid: grid } : { gap: gap }),
  };

  /**
   * Render the component.
   *
   * @return A React node.
   */
  return (
    <div {...(className && { className })}>
      {/**
       * TODO: La versión que tenemos de Splide no tiene el prop Children ya que en React 17 se añadia automaticamente pero en la 18 no
       * Cuando actualicemos Splide a la última versión, podemos quitar el @ts-ignore
       * @eslint-disable-next-line @typescript-eslint/ban-ts-comment
       * @ts-expect-error types are not for react 18 */}
      <Splide
        options={options}
        ref={splideRef}
        className={`${outline ? '[&_>_div]:!p-px' : ''} ${splideClassName}`}
        onMove={(splide) => adjustAllPaddings(splide)}
        onMoved={(splide) => checkPaginationState(splide)}
        onMounted={(splide) => {
          checkPaginationState(splide);
          adjustAllPaddings(splide);
        }}
        onResized={(splide) => checkPaginationState(splide)}
        Extensions={extensions.includes('grid') ? { Grid } : {}}
      >
        {renderSlides()}
      </Splide>

      {(!beginningOrEnd.disabledPrev || !beginningOrEnd.disabledNext) && (
        <div
          className={`mt-6 gap-4 ${alwaysShowArrows ? 'flex' : 'hidden md:flex'} ${arrowPosition === 'center' ? 'justify-center' : ''} ${arrowPosition === 'end' ? 'justify-end' : ''} `}
        >
          <IconButton
            style="dashed-outline"
            onClick={prevSlide}
            size="medium"
            disabled={type !== 'loop' && beginningOrEnd.disabledPrev}
            aria-label="Slide Anterior"
          >
            <ArrowLeft />
          </IconButton>

          <IconButton
            style="dashed-outline"
            onClick={nextSlide}
            size="medium"
            disabled={type !== 'loop' && beginningOrEnd.disabledNext}
            aria-label="Slide Siguiente"
          >
            <ArrowRight />
          </IconButton>
        </div>
      )}
    </div>
  );
};
